import React, { useEffect, useContext } from "react";
import ThemeContext from "../../context/theme-context";
import { ToggleLeft, ToggleRight } from "react-feather";
import * as classes from "./ThemeSelector.module.css";

const ThemeSelector = () => {
  const context = useContext(ThemeContext);

  let swapped = context.isThemeSwapped;

  useEffect(() => {
    const setProp = (property, value) => {
      document.documentElement.style.setProperty(property, value);
    };

    if (swapped === false) {
      setProp("--main-background-color", "#000");
      setProp("--main-font-color", "#fff");
      setProp("--secondary-light-background", "#37363a");
    } else if (swapped === true) {
      setProp("--main-background-color", "#ddd2b9");
      setProp("--main-font-color", "#37363a");
      setProp("--secondary-light-background", "#fdf0d4");
    }
  }, [context.isThemeSwapped, swapped]);

  const onChangeHandler = () => {
    context.themeSwapHandler();
  };

  return (
    <div className={classes.button} onClick={onChangeHandler}>
      {context.isThemeSwapped ? <ToggleLeft /> : <ToggleRight />}
    </div>
  );
};

export default ThemeSelector;
