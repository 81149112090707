import React, { useState, useEffect } from "react";
import * as classes from "./MainNavigation.module.css";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

const links = [
  {
    text: "Home",
    url: "/",
    number: "01 ",
  },
  {
    text: "Projects",
    url: "/projects",
    number: "02 ",
  },
  {
    text: "Contact",
    url: "/contact",
    number: "03 ",
  },
];

const MainNavigation = () => {
  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const menuHandler = () => {
    setMenuIsVisible(!menuIsVisible);
  };

  const resizer = () => {
    if (menuIsVisible) {
      setMenuIsVisible(false);
    }
  };

  useEffect(() => {
    const resizeWatcher = () => {
      window.addEventListener("resize", resizer);
    };
    resizeWatcher();

    return () => {
      window.removeEventListener("resize", resizer);
    };
  }, [menuIsVisible]);

  return (
    <nav className={classes.mainNav}>
      <ul className={!menuIsVisible ? classes.hideMenu : classes.showMenu}>
        {links.map((data) => {
          return (
            <li className={classes.link} key={uuidv4()}>
              <Link to={data.url}>
                <span>{data.number}</span>
                {data.text}
              </Link>
            </li>
          );
        })}
      </ul>
      <button
        className={
          menuIsVisible
            ? `${classes.hamburger} ${classes.rotate}`
            : classes.hamburger
        }
        onClick={menuHandler}
      >
        <div></div>
        <div></div>
        <div></div>
      </button>
    </nav>
  );
};

export default MainNavigation;
