import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GitHub, Linkedin } from "react-feather";
import * as classes from "./Footer.module.css";

const Footer = ({ data }) => {
  const linkData = useStaticQuery(graphql`
    {
      allMdx(sort: { order: DESC, fields: frontmatter___date }) {
        nodes {
          frontmatter {
            title
            path
          }
          id
          slug
        }
      }
    }
  `);

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <div className={classes.footerItem}>
          <h3>Experience</h3>
          <Link className={classes.link} to={"/"}>
            Home
          </Link>
          <Link className={classes.link} to={"/projects"}>
            Projects
          </Link>
          <Link className={classes.link} to={"/contact"}>
            Contact
          </Link>
        </div>

        <div className={classes.footerItem}>
          <h3>My Projects</h3>

          {linkData.allMdx.nodes.map((item) => {
            return (
              <Link
                key={item.id}
                className={classes.link}
                to={`${item.frontmatter.path}`}
              >
                {item.frontmatter.title}
              </Link>
            );
          })}
        </div>
        <div className={classes.footerItem}>
          <h3>Contact</h3>
          <a
            className={classes.iconLink}
            href="https://github.com/mulrooneydesign"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GitHub color="#ddd2b9" size={32} strokeWidth="1" />
            <span>GitHub</span>
          </a>
          <a
            className={classes.iconLink}
            href="https://www.linkedin.com/in/barry-diarmaid-mulrooney-391b1910/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin color="#ddd2b9" size={32} strokeWidth="1" />
            <span>LinkedIn</span>
          </a>
        </div>
      </div>
      <div className={classes.credits}>
        <p>
          Made in Barcelona by <strong>Barry Mulrooney</strong>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
