import React, { useState, useEffect } from "react";
import Logo from "./Logo";
import MainNavigation from "./MainNavigation";
import * as classes from "./Header.module.css";
import ThemeSelector from './ThemeSelector'

const Header = (props) => {
  const [isAtTop, setIsAtTop] = useState(false);

  const scrollPosition = () => {
    window.scrollY === 0 ? setIsAtTop(true) : setIsAtTop(false);
  };

  useEffect(() => {
    const scrollWatcher = () => {
      window.addEventListener("scroll", scrollPosition);
    };
    scrollWatcher();

    return () => {
      window.removeEventListener("scroll", scrollPosition);
    };
  }, [isAtTop]);

  return (
    <header
      className={
        isAtTop ? `${classes.header}` : `${classes.header} ${classes.scrolled}`
      }
    >
      <Logo />
      <MainNavigation />
      <ThemeSelector themeStatus={props.themeStatus} onChangeThemeHandler={props.onChangeThemeHandler} />
    </header>
  );
};

export default Header;
