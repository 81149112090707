import React from "react";
import * as classes from "./Logo.module.css";
import { Link } from "gatsby";

const Logo = () => {
  return (
    <div className={classes.logo}>
      <Link className={classes.link} to="/">
        Hi, I'm Barry!
      </Link>
    </div>
  );
};

export default Logo;
