import React, { useState, useEffect } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Seo from "../Seo/Seo";
import ThemeContext from "../../context/theme-context";
import * as classes from './Layout.module.css'

const Layout = (props) => {
  const [isThemeSwapped, setIsThemeSwapped] = useState("");
  
  useEffect(() => {
    const isThemeSwapped = JSON.parse(localStorage.getItem("isThemeSwapped"));

    if (isThemeSwapped) {
      setIsThemeSwapped(isThemeSwapped);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("isThemeSwapped", JSON.stringify(isThemeSwapped));
  }, [isThemeSwapped]);

  const themeSwapHandler = () => {
    setIsThemeSwapped(isThemeSwapped => !isThemeSwapped);
  };

  return (
    <ThemeContext.Provider
      value={{
        isThemeSwapped: isThemeSwapped,
        themeSwapHandler: themeSwapHandler,
      }}
    >
      <Header />
      <Seo title={props.title} />
       <main className={classes.main}>{props.children}</main>
      <Footer />
    </ThemeContext.Provider>
  );
};

export default Layout;
